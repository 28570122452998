<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';
	import NProgress from 'vue-nprogress';

	Vue.component("HomeSlider", () => import("@frontend/part/HomeSlider.vue"))

	export default {
		extends: BaseVue,
		data() {
			 return {
			 	dataGoodness:{},
			 	setGoodness:{},
			 	backLeft:{},
			 	backRight:{},
			 	articleInfo:{},
			 	articleNews:{},
			 	setSosmed:{},
			 	dataSosmed:{},
			 	quiz:{}
			 }
		},
		computed: {

		},
		watch:{
			$route(){
				this.quizView()
			}
		},
		async mounted() {
			this.$set(this.$root, 'page', this)
			this.getGoodness()
			this.getArticle()
			this.getSosmed()
			this.getQuiz()
			this.quizView()
			this.compScroll()
		},
		methods: {
			getGoodness(){
				Gen.apirest("/goodness", {},(err,resp)=>{
					if(err) console.log("home goodness error "+err)
					this.dataGoodness = resp.data
					this.setGoodness = resp.setings
					this.backLeft = resp.backLeft
					this.backRight = resp.backRight
					setTimeout(() => {
						SEMICOLON.widget.carousel()
						$(".wellness-carousel").trigger("to.owl.carousel", [1, 1]);
					}, 300)
				})
			},
			getArticle(){
				Gen.apirest("/home-article", {},(err,resp)=>{
					if(err) console.log("home article error "+err)
					this.articleInfo = resp.dataInfo
					this.articleNews = resp.dataNews
				})
			},
			getQuiz(){
				Gen.apirest("/home-quiz", {},(err,resp)=>{
					if(err) console.log("home quiz error "+err)
					this.quiz = resp.quiz
				})
			},
			getSosmed(){
				Gen.apirest("/home-social", {},(err,resp)=>{
					if(err) console.log("home scial media error "+err)
					this.setSosmed = resp.showSocmed
					this.dataSosmed = resp.data
					setTimeout(() => {
						AOS.init({ once: true });
					}, 500)
				})
			},
			quizView(){
				$(document).on('click','.qw-cta', function(){
					if ($(this).hasClass("true-answer")) {
						$(".toggleView").toggle();
						$(".tryAgain").text("");
						$(".qw-cta").removeClass("wrongChoice");
						$(".qw-cta__ap").removeClass("d-none");
						$(".qw-cta__ap.bg-wrong").addClass("d-none");
					} else {
						$(this).addClass("wrongChoice")
						$(this).find(".qw-cta__ap").toggleClass("d-none");
						$(".tryAgain").text("Coba Lagi!")
					}
				})
			},
			compScroll(){
				var lastTop = 0;
				if ($(window).width() <= 991){
					var scroller = 5;
				}else{
					var scroller = 10;
				}
				$(window).scroll(function () {
					let st = $(this).scrollTop();
					if (st > lastTop) {
						$(".taste-comp.right").css("top", Number($(".taste-comp.right").css('top').replace('px',
							'')) - scroller + "px");
						$(".taste-comp.left").css("top", Number($(".taste-comp.left").css('top').replace('px',
							'')) + scroller + "px");
					} else {
						$(".taste-comp.right").css("top", Number($(".taste-comp.right").css('top').replace('px',
							'')) + scroller + "px");
						$(".taste-comp.left").css("top", Number($(".taste-comp.left").css('top').replace('px',
							'')) - scroller + "px");
					}
					lastTop = st;
				});
			}
		},
	};
</script>
<template>
	<div>
	<HomeSlider />
	<!-- Content -->
	<section id="content">
		<div class="content-wrap py-0">
			<section class="section nobg pt-4 pt-md-2" v-if="setGoodness.as_val=='Y'">
				<div class="taste-comp right" :alt="backRight.as_name" :style="'background-image: url('+uploader(backRight.as_val)+');'"></div>
				<div class="taste-comp left" :alt="backLeft.as_name" :style="'background-image: url('+uploader(backLeft.as_val)+');'"></div>
				<div class="container overflow-hidden" data-aos="fade-up">
					<div class="heading-block center">
						<h2>{{web.lbl_kebaikan}}</h2>
					</div>
					<div class="row justify-content-center">
						<div class="col-xl-7 col-lg-9 col-md-10 overflow-visible position-static">
							<div class="owl-carousel carousel-widget wellness-carousel d-lg-none" data-items="1"
								data-nav="true" data-pagi="true" data-margin="22">
								<div class="hl-benefit" v-for="(vg,kg) in dataGoodness" :key="kg">
									<div class="hlb-portrait">
										<img :src="uploader(vg.ag_img)" :alt="vg.ag_title">
									</div>
									<div class="hlb-desc">
										<h3>{{vg.ag_title}}</h3>
										<p>{{vg.sort_desc}}</p>
										<router-link :to="{name:'About'}">{{web.lbl_learn_more}} <i class="icon-line-arrow-right"></i></router-link>
									</div>
								</div>
							</div>

							<div class="owl-carousel carousel-widget wellness-carousel d-none d-lg-block position-static"
								data-stage-padding="160" data-items="1" data-nav="true" data-pagi="true"
								data-margin="0">
								<div class="hl-benefit" v-for="(vg,kg) in dataGoodness" :key="kg">
									<div class="hlb-portrait">
										<img :src="uploader(vg.ag_img)" :alt="vg.ag_title">
									</div>
									<div class="hlb-desc">
										<h3>{{vg.ag_title}}</h3>
										<p>{{vg.sort_desc}}</p>
										<router-link :to="{name:'About'}">{{web.lbl_learn_more}} <i class="icon-line-arrow-right"></i></router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div class="health-info">
				<div class="row mx-0">
					<div class="col-md-6 px-0">
						<router-link  v-if="articleInfo != null" :to="{name:'HealthInfoDetail',params:{cat:articleInfo.aic_slug,slug:articleInfo.an_slug}}" class="article mt-0" style="--articlebg:#dee9f9;" data-aos="fade-up">
							<div class="row">
								<div class="col-xl-6 col-lg-5 px-0">
									<div class="article__img">
										<img :src="uploader(articleInfo.an_img)" :alt="articleInfo.an_title">
									</div>
								</div>
								<div class="col-xl-6 col-lg-7">
									<div class="article-desc">
										<span class="article-desc__label">{{web.menu_health_info}}</span>
										<h3 class="article-desc__title" v-tooltip="articleInfo.an_title.length>50 ? articleInfo.an_title:''">{{(articleInfo.an_title.substr(0,50))+(articleInfo.an_title.length>50 ? '...':'')}}</h3>
										<span class="article-desc__more">{{web.lbl_read_more}} <i class="icon-line-arrow-right"></i></span>
									</div>
								</div>
							</div>
						</router-link>
						<router-link v-if="articleNews != null" :to="{name:'NewsEventDetail',params:{slug:articleNews.an_slug}}" class="article mt-0" style="--articlebg:#E7F175;" data-aos="fade-up">
							<div class="row">
								<div class="col-xl-6 col-lg-5 px-0">
									<div class="article__img">
										<img :src="uploader(articleNews.an_img)" :alt="articleNews.an_title">
									</div>
								</div>
								<div class="col-xl-6 col-lg-7">
									<div class="article-desc">
										<span class="article-desc__label">{{web.menu_news_event}}</span>
										<h3 class="article-desc__title" v-tooltip="articleNews.an_title.length>50 ? articleNews.an_title:''">{{(articleNews.an_title.substr(0,50))+(articleNews.an_title.length>50 ? '...':'')}}</h3>
										<span class="article-desc__more">{{web.lbl_read_more}} <i class="icon-line-arrow-right"></i></span>
									</div>
								</div>
							</div>
						</router-link>
					</div>
					<div class="col-md-6 px-0 bg-blue" v-if="quiz != null">
						<div class="qotd" data-aos="zoom-out">
							<span class="q-badge d-inline-block">{{web.lbl_hiloquestion}}</span>
							<div class="toggleView animated faster fadeIn">
								<div class="question-wrap">
									<h3>{{quiz.aq_question}}</h3>
								</div>
								<div class="row justify-content center">
									<div class="col-lg-6" data-aos="fade-up" data-aos-delay="125">
										<div role="button" :class="quiz.aq_key == 'A' ? 'qw-cta true-answer' : 'qw-cta'" v-tooltip="quiz.aq_a">
											<span class="qw-cta__ap bg-1">a</span>
											<span class="qw-cta__ap bg-wrong d-none">X</span>
											<span class="qw-cta-answer">{{quiz.aq_a}}</span>
										</div>
									</div>
									<div class="col-lg-6" data-aos="fade-up" data-aos-delay="250">
										<div role="button" :class="quiz.aq_key == 'B' ? 'qw-cta true-answer' : 'qw-cta'" v-tooltip="quiz.aq_b">
											<span class="qw-cta__ap bg-2">b</span>
											<span class="qw-cta__ap bg-wrong d-none">X</span>
											<span class="qw-cta-answer">{{quiz.aq_b}}</span>
										</div>
									</div>
									<div class="col-lg-6" data-aos="fade-up" data-aos-delay="375">
										<div role="button" :class="quiz.aq_key == 'C' ? 'qw-cta true-answer' : 'qw-cta'" v-tooltip="quiz.aq_c">
											<span class="qw-cta__ap bg-3">c</span>
											<span class="qw-cta__ap bg-wrong d-none">X</span>
											<span class="qw-cta-answer">{{quiz.aq_c}}</span>
										</div>
									</div>
									<div class="col-lg-6" data-aos="fade-up" data-aos-delay="500">
										<div role="button" :class="quiz.aq_key == 'D' ? 'qw-cta true-answer' : 'qw-cta'" v-tooltip="quiz.aq_d">
											<span class="qw-cta__ap bg-4">d</span>
											<span class="qw-cta__ap bg-wrong d-none">X</span>
											<span class="qw-cta-answer">{{quiz.aq_d}}</span>
										</div>
									</div>
								</div>
								<h4 class="tryAgain mb-0 text-center"></h4>
							</div>
							<div class="toggleView animated faster fadeIn" style="display: none;">
								<div class="answerBrief">
									<img :src="assets('fo_images','/true-answer.png')" alt="Jawabanmu Benar!">
									<h3>Jawabanmu <span class="text-success">BENAR!</span></h3>
									<p>{{quiz.aq_resume}}</p>
									<div class="form-row justify-content-center">
										<div class="col-lg-8">
											<a :href="quiz.aq_link" class="qw-cta true-answer text-center" role="button">
												<span class="qw-cta-answer">{{web.lbl_show_article}}</span>
											</a>
										</div>
										<div class="col-lg-4">
											<div class="qw-cta true-answer text-center qw-cta--transparent" role="button">
												<span class="qw-cta-answer">{{web.lbl_kembali}}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<section class="section social-media pt-4" v-if="setSosmed.as_val =='Y'">
				<div class="taste-comp left" style="background-image:url('/cloud-left.jpg')"></div>
				<div class="taste-comp right" style="background-image:url('/cloud-right.jpg')"></div>
				<div class="container">
					<div class="heading-block center" data-aos="fade-up">
						<h2>{{web.lbl_sosmed}}</h2>
					</div>
					<div class="row align-items-center justify-content-center">
						<div class="col-md-6 col-lg-4" data-aos="fade-up" v-for="(sd,sk) in dataSosmed" :key="sk">
							<div class="form-row align-items-center" >
								<div class="col-3">
									<div class="sosmed-img">
										<img :src="uploader(sd.am_logo)" :alt="sd.am_name">
									</div>
								</div>
								<div class="col-9">
									<div class="sosmed">
										<div class="social-icons">
											<a v-if="sd.am_link_fb != null" :href="sd.am_link_fb" target="_blank" class="social-icon si-rounded si-colored si-facebook">
												<i class="icon-facebook"></i>
												<i class="icon-facebook"></i>
											</a>
											<a v-if="sd.am_link_tw != null" :href="sd.am_link_tw" target="_blank" class="social-icon si-rounded si-colored si-twitter">
												<i class="icon-twitter"></i>
												<i class="icon-twitter"></i>
											</a>
											<a v-if="sd.am_link_ig != null" :href="sd.am_link_ig" target="_blank" class="social-icon si-rounded si-colored si-instagram">
												<i class="icon-instagram"></i>
												<i class="icon-instagram"></i>
											</a>
											<a v-if="sd.am_link_yt != null" :href="sd.am_link_yt" target="_blank" class="social-icon si-rounded si-colored si-youtube">
												<i class="icon-youtube"></i>
												<i class="icon-youtube"></i>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>

	</section><!-- #content end -->
</div>
</template>